@tailwind base;
@tailwind components;
@tailwind utilities;

@import url('https://fonts.googleapis.com/css2?family=Maven+Pro:wght@400;500;600;700&display=swap');

body {
  margin: 0;
  font-family: 'Maven Pro', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.ant-btn-primary{
  background-color: blue;
}
.ant-btn-primary:hover{
  border-color: blue;
  background-color: white !important;
  color: blue !important;
}

.ant-switch{
  background-color: grey;
}

.ant-menu-dark.ant-menu-inline .ant-menu-sub.ant-menu-inline{
  background-color: #307FE2 !important;
}
.ant-menu-dark .ant-menu-item-selected{
  background-color: #87bcff;
}